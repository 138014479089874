<template>
  <div :style="getElementStyle" v-show="isHide" v-loading="loading">
    <div v-if="!isDefalutPos">
      <div style="display: flex">
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide' && hasLabel"
          :style="getLabelStyles"
          style="margin-right: 5px"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            title="Help"
            trigger="click"
            :content="data.description"
            style="margin-right: 5px"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span>
          <div
            v-if="
              isView && data.properties.filed_content != 'Hide' && !hasLabel
            "
          >
            <template v-if="form[data.key]">{{ form[data.key] }} </template>
            <template v-else>-</template>
          </div>
          <div v-else>
            <el-input
              type="text"
              :readonly="true"
              :style="getStyle"
              @change="applyFormRules"
              v-model="form[data.key]"
            ></el-input>
          </div>
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
        </span>
      </div>
      <el-row
        v-if="
          isFromDocument
            ? ''
            : !data.properties.hideLabel &&
              data.properties.filed_content != 'Hide'
        "
      >
        <el-col>
          <span>
            <label for="text" v-if="hasLabel" :style="getLabelStyles">
              <!-- {{ data.label }} -->
            </label>
          </span>
          <span
            v-if="data.description && data.properties.filed_content != 'Hide'"
            style="color: #c0c4cc; font-weight: bold"
          >
          </span>
        </el-col>
      </el-row>
      <el-row>
        <template
          v-if="
            isFromDocument
              ? data.type == 'AUTO_INCREMENT_NUMBER'
              : data.inputType == 'AUTO_INCREMENT_NUMBER' &&
                data.properties.filed_content != 'Hide' &&
                hasLabel
          "
        >
          <input type="hidden" v-model="data.auto_increment_starting_number" />
          <input type="hidden" v-model="data.auto_increment_prefix" />
        </template>
      </el-row>
    </div>

    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        style="display: contents"
        ref="colRef"
        :style="computedStyles"
      >
        <label
          for="text"
          v-if="hasLabel && data.properties.filed_content != 'Hide' && hasLabel"
          :style="getLabelStyles"
          style="margin-right: 5px"
          :class="{ 'highlight-border': highlight }"
          >{{ label || data.label }}</label
        >
        <span
          v-if="data.validations.required && hasLabel"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            title="Help"
            trigger="click"
            :content="data.description"
            style="margin-right: 5px"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
        <span v-if="!isView && data.properties && data.properties.allow_to_regenerate">
        <el-tooltip content="click to regenerate" placement="top">
            <i style="cursor:pointer" @click="generateRandomText()" class="el-icon-refresh"></i>
        </el-tooltip>
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div v-if="isView && data.properties.filed_content != 'Hide'">
          <template v-if="form[data.key]">{{ form[data.key] }} </template>
          <template v-else>-</template>
        </div>
        <div v-else-if="isFromDocument">
          <el-input
            type="text"
            :readonly="true"
            :style="getStyle"
            v-model="form[data.key]"
          ></el-input>
        </div>
        <div v-else-if="isView">
          <p :style="getStyle" @click="checkSelectText">{{ form[data.key] }}</p>
        </div>
        <div v-else>
          <input
            type="text"
            :readonly="true"
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            v-model="form[data.key]"
            class="slv"
          />
        </div>
        <p v-if="hasError" style="color: red; font-size: 14px">
          {{ errorMsg }}
        </p>
        <el-row
          v-if="
            isFromDocument
              ? ''
              : !data.properties.hideLabel &&
                data.properties.filed_content != 'Hide'
          "
        >
          <el-col>
            <span>
              <label for="text" v-if="hasLabel" :style="getLabelStyles">
              </label>
            </span>
            <span
              v-if="data.description && data.properties.filed_content != 'Hide'"
              style="color: #c0c4cc; font-weight: bold"
            >
            </span>
          </el-col>
        </el-row>
        <el-row>
          <template
            v-if="
              isFromDocument && data.value === ''
                ? data.type === 'AUTO_INCREMENT_NUMBER'
                : data.inputType == 'AUTO_INCREMENT_NUMBER' &&
                  data.properties.filed_content != 'Hide' &&
                  hasLabel
            "
          >
            <input
              type="hidden"
              v-model="data.auto_increment_starting_number"
            />
            <input type="hidden" v-model="data.auto_increment_prefix" />
          </template>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
// import templateConstants from "../../../constants/lg-en/templates";
import { mapGetters } from "vuex";
// import { postAPICall } from "@/helpers/httpHelper";

export default {
  name: "templates-formComponentsExecute-SingleLineTextExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "entityDataId",
    "entityId",
    "isHideField",
    "highlight",
    "fromEntityViews",
  ],
  mounted() {
    console.log("this.data 000",this.data.inputType, this.data);
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
      this.inputTextAlignment =
        this.data.styles && this.data.styles.inputTextAlignments
          ? this.data.styles.inputTextAlignments
          : "left";
    }
    if (
      !this.form[this.data.key] &&
      this.data.input_type != "ENTITY_VARIABLE"
    ) {
      this.generateRandomText();
    }
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    ...mapGetters("formBuilders", ["getFormBuilder"]),
    ...mapGetters("auth", ["getUserType"]),
    ...mapGetters("entities", ["getEntityDataExists"]),
    getStyle() {
      return `height:${this.data.height - 30}px; font-weight: 400`;
    },
    isHide() {
      if (this.isHideField || this.data?.properties?.filed_content == "Hide") {
        return false;
      }
      return true;
    },
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    // isReadOnly() {
    //   return (
    //     this.getUserType?.name !== "OWNER" ||
    //     this.data.generate_value_from_actions
    //   );
    // },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      previousFormData: {},
      initialValue: "",
      inputTextAlignment: "right",
    };
  },
  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    getMinValue() {
      let min = 5;
      switch (this.data?.selected_preferences?.length) {
        case 1:
          min = 8;
          break;
        case 2:
          min = 6;
          break;
        case 4:
          min = 4;
      }
      this.data.random_text_length = min;
      return min;
    },
    generateRandomText() {
      const preferences = this.data.selected_preferences || [];
      const length = this.data.random_text_length || this.getMinValue();
      let randomText = this.data.auto_increment_prefix ||  "";
      let characters = "";

      if (preferences.includes("Use Letters")) {
        characters += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (preferences.includes("Use non capitalized")) {
        characters += "abcdefghijklmnopqrstuvwxyz";
      }
      if (preferences.includes("Use Numbers")) {
        characters += "0123456789";
      }
      if (preferences.includes("Use Special Characters")) {
        characters += "!@#$%^&*()_+[]{}|;:,.<>?";
      }

      if (characters === "")
        this.$set(this.form,this.data.key,randomText);

      for (let i = 0; i < length; i++) {
        randomText += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
    this.$set(this.form,this.data.key,randomText);
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "AUTO_INCREMENT_NUMBER") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      return false
    },
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        this.inputTextAlignment = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-border {
  color: red;
}
</style>
